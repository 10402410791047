import React, { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { Controller } from "react-hook-form";
import {
  selectIsLoadingAccount,
  selectLanguage,
} from "store/account/selectors";
import { i18n } from "@lingui/core";
import { t } from "@lingui/macro";
import { checkIsEmail } from "forms/validators";
import { REGISTER } from "store/account/slice";
import timezoneOptions from "components/Select/options/timezoneOptions";
import {
  Box,
  Button,
  Link,
  MenuItem,
  Typography,
  Paper,
  CircularProgress,
  TextField,
  InputAdornment,
  Tooltip,
  Container,
  Grid,
} from "@mui/material";
import { bootstrap, logo } from "styles/theme";
import useQuery from "hooks/utilities/useQuery";
import languageOptions from "components/Select/options/languageOptions";
import accountSlice from "store/account/slice";
import checkIsPhoneNumberValid from "forms/validators/checkIsValidPhoneNumber";
import {
  InfoOutlined as InfoIcon,
  Email as EmailIcon,
  Person as PersonIcon,
  Phone as PhoneIcon,
  Language as LanguageIcon,
  AccessTime as AccessTimeIcon,
} from "@mui/icons-material";
import { showSnackbar } from "store/ui/snackbar/slice";
import { isMobile } from "react-device-detect";
import { sendToMobileInterface } from "utilities/mobile/interface";

export default function Register({ form }) {
  const query = useQuery();
  const dispatch = useDispatch();
  const history = useHistory();
  const language = useSelector(selectLanguage);
  const {
    handleSubmit,
    formState: { errors },
    control,
    setValue,
    clearErrors,
  } = form;
  const isLoading = useSelector(selectIsLoadingAccount);
  const { setLanguage } = accountSlice.actions;

  useEffect(() => {
    const username = query.get("u");
    username && setValue("email", username);
  }, [setValue, query]);

  const dispatchRegister = useCallback(
    (data) => {
      dispatch(
        REGISTER(data, {
          onSuccess: () => {
            history.push("/register/success");
          },
          onError: (message) => {
            dispatch(showSnackbar({ message, severity: "error" }));
          },
        })
      );
    },
    [dispatch, history]
  );

  return (
    <Container maxWidth="sm">
      <Paper elevation={3} sx={{ p: 4, borderRadius: 2 }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <img src={logo} alt="Logo" style={{ width: 280, marginBottom: 24 }} />
          <Typography component="h1" variant="h5" gutterBottom>
            {i18n._(t`Create an Account`)}
          </Typography>

          <Box
            component="form"
            onSubmit={handleSubmit(dispatchRegister)}
            sx={{ mt: 3, width: "100%" }}
          >
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Controller
                  name="email"
                  control={control}
                  rules={{
                    required: i18n._(t`Email is required`),
                    validate: checkIsEmail,
                  }}
                  render={({ value, onChange }) => (
                    <TextField
                      fullWidth
                      required
                      label={i18n._(t`Email`)}
                      type="email"
                      autoComplete="email"
                      error={!!errors.email}
                      helperText={errors.email?.message}
                      disabled={isLoading}
                      value={value}
                      onChange={onChange}
                      onFocus={() => clearErrors("email")}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <EmailIcon />
                          </InputAdornment>
                        ),
                      }}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Controller
                  name="firstName"
                  control={control}
                  rules={{ required: i18n._(t`First name is required`) }}
                  render={({ value, onChange }) => (
                    <TextField
                      fullWidth
                      required
                      label={i18n._(t`First Name`)}
                      error={!!errors.firstName}
                      helperText={errors.firstName?.message}
                      disabled={isLoading}
                      value={value}
                      onChange={onChange}
                      onFocus={() => clearErrors("firstName")}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <PersonIcon />
                          </InputAdornment>
                        ),
                      }}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Controller
                  name="lastName"
                  control={control}
                  rules={{ required: i18n._(t`Last name is required`) }}
                  render={({ value, onChange }) => (
                    <TextField
                      fullWidth
                      required
                      label={i18n._(t`Last Name`)}
                      error={!!errors.lastName}
                      helperText={errors.lastName?.message}
                      disabled={isLoading}
                      value={value}
                      onChange={onChange}
                      onFocus={() => clearErrors("lastName")}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <PersonIcon />
                          </InputAdornment>
                        ),
                      }}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Controller
                  name="phoneNumber"
                  control={control}
                  rules={{
                    required: i18n._(t`Phone number is required`),
                    validate: (value) => checkIsPhoneNumberValid(value, true),
                  }}
                  render={({ value, onChange }) => (
                    <TextField
                      fullWidth
                      required
                      label={i18n._(t`Phone Number`)}
                      error={!!errors.phoneNumber}
                      helperText={errors.phoneNumber?.message}
                      disabled={isLoading}
                      value={value}
                      onChange={onChange}
                      onFocus={() => clearErrors("phoneNumber")}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <PhoneIcon />
                          </InputAdornment>
                        ),
                        endAdornment: (
                          <InputAdornment position="end">
                            <Tooltip
                              title={i18n._(
                                t`Phone numbers are used for account-related communications only`
                              )}
                            >
                              <InfoIcon />
                            </Tooltip>
                          </InputAdornment>
                        ),
                      }}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Controller
                  name="gmtOffsetForNotifications"
                  control={control}
                  rules={{ required: i18n._(t`Please select a timezone`) }}
                  render={({ value, onChange }) => (
                    <TextField
                      fullWidth
                      required
                      select
                      label={i18n._(t`Timezone`)}
                      error={!!errors.gmtOffsetForNotifications}
                      helperText={errors.gmtOffsetForNotifications?.message}
                      disabled={isLoading}
                      value={value}
                      onChange={onChange}
                      onFocus={() => clearErrors("gmtOffsetForNotifications")}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <AccessTimeIcon />
                          </InputAdornment>
                        ),
                      }}
                    >
                      {timezoneOptions.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </TextField>
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  select
                  label={i18n._(t`Language`)}
                  value={language.value}
                  onChange={(e) => {
                    const value = e.target.value;
                    const selectedOption = languageOptions.find(
                      (option) => option.value === value
                    );
                    localStorage.setItem("language", value);
                    dispatch(
                      setLanguage({
                        label: selectedOption.label,
                        value,
                      })
                    );
                  }}
                  disabled={isLoading}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <LanguageIcon />
                      </InputAdornment>
                    ),
                  }}
                >
                  {languageOptions.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
            </Grid>

            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2, backgroundColor: bootstrap.primary }}
              disabled={isLoading}
            >
              {isLoading ? <CircularProgress size={24} /> : i18n._(t`Register`)}
            </Button>

            <Grid container justifyContent="flex-end">
              <Grid item>
                <Link
                  component="button"
                  variant="body2"
                  onClick={() =>
                    isMobile
                      ? sendToMobileInterface("cancelled")
                      : history.push("/login")
                  }
                >
                  {i18n._(t`Already have an account? Sign in`)}
                </Link>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Paper>
    </Container>
  );
}
