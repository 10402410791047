import { takeLatest, put } from "redux-saga/effects";
import slice, {
  CANCEL_STRIPE_SUBSCRIPTION,
} from "store/stripe/subscription/slice";
import { cancelStripeSubscription } from "resources/services/stripeService";
import { FETCH_STRIPE_CUSTOMER } from "store/stripe/customer/slice";

function* fetch({ payload, meta }) {
  const { fetching, error, success } = slice.actions;

  yield put(fetching());
  try {
    yield cancelStripeSubscription(payload);

    meta?.onSuccess && meta.onSuccess();

    yield put(FETCH_STRIPE_CUSTOMER());
    yield put(success());
  } catch (e) {
    meta?.onError && meta.onError();
    yield put(error(e));
  }
}

export default function* watchCancelSubscription() {
  yield takeLatest(CANCEL_STRIPE_SUBSCRIPTION.type, fetch);
}
