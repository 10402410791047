import { takeLatest, all, call, put, select } from "redux-saga/effects";
import accountSlice, { LOGIN } from "../slice";
import { getToken, testAuthToken } from "resources/services/tokenService";
import jwtDecode from "jwt-decode";
import {
  updateLanguage,
  getLBService,
  getMyUser,
  getUserMapSettings,
} from "resources/services/userService";
import {
  selectIsEcommerceSite,
  selectLanguage,
  selectMyUser,
} from "../selectors";
import { getMyCompany } from "resources/services/companyService";
import { persistor } from "configurations/redux";
import { PURGE } from "store/rootReducer";
import { i18n } from "@lingui/core";
import { t } from "@lingui/macro";
import { checkIsEmail } from "forms/validators";
import { createStripeCustomer } from "resources/services/stripeService";
import stripeSlice from "store/stripe/customer/slice";
import deviceSlice from "store/device/slice";
import { getFirebaseToken } from "resources/services/firebaseService";
import { signInWithCustomToken } from "firebase/auth";
import { firebaseAuth } from "configurations/firebase";
import getClientError from "utilities/getClientError";
import UserRoles from "utilities/enumerations/UserRoles";
import { getAllDeviceTypes } from "resources/services/deviceService";
import normalize from "utilities/array/normalize";
import getCountryCode from "utilities/getCountryCode";

export const loginRequests = [
  call(getLBService),
  call(getMyUser),
  call(getMyCompany),
];

function* fetch({ payload, meta }) {
  const isEcommerceSite = yield select(selectIsEcommerceSite);
  const { setStripeCustomer } = stripeSlice.actions;
  const { setDeviceInfo } = deviceSlice.actions;

  const {
    username,
    password,
    access_token: token,
    expires_in: expires,
  } = payload;

  const {
    fetching,
    error,
    success,
    setAccessToken,
    setExpiresAt,
    setDocuments,
    setMyUser,
    setMyCompany,
    setMapSettings,
    setPermissions,
    setLoginStatus,
  } = accountSlice.actions;

  let access_token = token,
    expires_in = expires;

  yield put(fetching());

  // attempt a non-blocking firebase login
  if (username && password) {
    yield put(setLoginStatus(i18n._(t`Validating login credentials`)));

    try {
      const response = yield getFirebaseToken(username, password);
      const firebase_token = response.data.token;

      if (firebase_token) {
        yield signInWithCustomToken(firebaseAuth, firebase_token);
      }
    } catch (e) {
      console.error("Error logging into firebase.", e);
    }
  }

  try {
    // NOTE: if user is logging in with username password update access token from response
    if (username && password) {
      const _token = yield getToken(username, password);

      if (_token.error) throw new Error(_token.error);

      access_token = _token.access_token;
      expires_in = _token.expires_in;
    }

    if (!access_token || !expires_in) {
      throw new Error(i18n._(t`No token has been set.`));
    }

    const isValid = yield testAuthToken(access_token);

    if (!isValid) {
      throw new Error(i18n._(t`Token is invalid or session has expired.`));
    }

    const decodedToken = jwtDecode(access_token);

    // NOTE: if we can get a valid email from the decoded token, store it incase the login fails
    if (checkIsEmail(decodedToken.sub)) {
      sessionStorage.setItem("email", decodedToken.sub);
    }

    const permissionsObject = JSON.parse(decodedToken.permissions);
    const expiresAt = JSON.stringify(expires_in * 1000 + new Date().getTime());

    yield put(setPermissions(permissionsObject));
    yield put(setAccessToken(access_token));
    yield put(setExpiresAt(expiresAt));

    const language = yield select(selectLanguage);
    yield updateLanguage(decodedToken.uid, language.value);

    yield put(setLoginStatus(i18n._(t`Retrieving user information`)));

    const [documents, user, company] = yield all(loginRequests);

    // NOTE: try and get a country code of the companies country field or default to CA
    company.country = getCountryCode(company.country);

    yield put(setDocuments(documents));
    yield put(setMyUser(user));
    yield put(setMyCompany(company));

    // NOTE: only create / fetch the stripe customer IF:
    // 1. on ecommerce site
    // 2. not a legacy billing company
    // 3. not a temporary user
    if (
      isEcommerceSite &&
      !company?.isLegacyBilling &&
      user.role !== UserRoles.TemporaryUser
    ) {
      const stripeCustomer = yield createStripeCustomer({
        email: user.email,
        name: `${user.firstName} ${user.lastName}`,
      });

      yield put(setStripeCustomer(stripeCustomer));
    }
  } catch (e) {
    // NOTE: clear store if any login steps fail, this will cause a logout
    yield put(PURGE());
    yield persistor.flush();
    yield persistor.persist();

    const message = getClientError(e);

    yield put(setLoginStatus(""));
    yield put(error(message));
    meta?.onError && meta.onError(message);

    return;
  }

  const myUser = yield select(selectMyUser);

  // NOTE: silently fail if any of the following requests don't go through as they aren't necessary for login

  yield put(setLoginStatus(i18n._(t`Retrieving user settings`)));

  try {
    // NOTE: retrieve user's map settings
    const mapSettings = yield getUserMapSettings(myUser.uid);

    if (mapSettings) {
      yield put(setMapSettings(mapSettings));
    }
  } catch (e) {
    console.error("Unable to retrieve user map settings.");
  }

  try {
    // NOTE: retrieve data on all the devices we offer
    const deviceInfo = yield getAllDeviceTypes();
    const normalizedDevices = normalize(deviceInfo, "type");

    if (normalizedDevices) {
      yield put(setDeviceInfo(normalizedDevices));
    }
  } catch (e) {
    console.error("Unable to retrieve all device information.");
  }

  yield put(setLoginStatus(""));
  yield put(success());

  meta?.onSuccess && meta.onSuccess(myUser);
}

export default function* watchLogin() {
  yield takeLatest(LOGIN.type, fetch);
}
