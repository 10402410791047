import { takeLatest, put, delay, select } from "redux-saga/effects";
import slice, {
  UPDATE_STRIPE_SUBSCRIPTION,
} from "store/stripe/subscription/slice";
import accountSlice from "store/account/slice";
import { updateStripeSubscription } from "resources/services/stripeService";
import {
  FETCH_STRIPE_CUSTOMER,
  FETCH_STRIPE_CUSTOMER_AS_ADMIN,
} from "store/stripe/customer/slice";
import getClientError from "utilities/getClientError";
import { getMyUser } from "resources/services/userService";
import { selectCancelledDevices } from "store/stripe/cart/selectors";

function* fetch({ payload, meta }) {
  const { id, data, user } = payload;
  const { fetching, error, success } = slice.actions;
  const { setMyUser } = accountSlice.actions;

  const cancelledAssetIds = yield select(selectCancelledDevices);

  yield put(fetching());

  try {
    const res = yield updateStripeSubscription(
      id,
      data,
      user,
      cancelledAssetIds || []
    );

    if (res.error) {
      throw new Error(res.error);
    }

    // NOTE: give some delay so the webhook can action the new devices and remove any user flags
    yield delay(2000);

    if (!user) {
      yield put(FETCH_STRIPE_CUSTOMER());
    } else {
      yield put(FETCH_STRIPE_CUSTOMER_AS_ADMIN(user));
    }

    const myUser = yield getMyUser();

    yield put(setMyUser(myUser));
    yield put(success());

    meta?.onSuccess && meta.onSuccess(res);
  } catch (e) {
    const errorMessage = getClientError(e);
    meta?.onError && meta.onError(errorMessage);
    yield put(error(errorMessage));
  }
}

export default function* watchUpdateSubscription() {
  yield takeLatest(UPDATE_STRIPE_SUBSCRIPTION.type, fetch);
}
