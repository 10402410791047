import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { Box, Button, CircularProgress, Typography } from "@mui/material";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import { t } from "@lingui/macro";
import { isMobile } from "react-device-detect";
import { FETCH_MY_USER } from "store/account/slice";
import { sendToMobileInterface } from "utilities/mobile/interface";

export default function SubscriptionUpdateSuccess() {
  const dispatch = useDispatch();
  const history = useHistory();
  const [progress, setProgress] = useState(0);
  const [state, setState] = useState("processing");
  const [isRedirecting, setIsRedirecting] = useState(false);

  useEffect(() => {
    const TOTAL_DURATION = 4000;
    const STEPS = 200;
    const INTERVAL = TOTAL_DURATION / STEPS;

    // Progress animation
    const timer = setInterval(() => {
      setProgress((prevProgress) => {
        if (prevProgress >= 99.5) {
          clearInterval(timer);
          dispatch(
            FETCH_MY_USER({
              onSuccess: () => {
                setState("success");
                // Show success message for 2 seconds, then show redirecting
                setTimeout(() => {
                  setIsRedirecting(true);
                  setTimeout(() => {
                    if (isMobile) {
                      sendToMobileInterface("success");
                    } else {
                      history.push("/Assets");
                    }
                  }, 500);
                }, 2000);
              },
            })
          );
          return 100;
        }
        return prevProgress + 100 / STEPS;
      });
    }, INTERVAL);

    return () => {
      clearInterval(timer);
    };
  }, [dispatch, history]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        minHeight: "100vh",
        gap: 3,
        p: 3,
        maxWidth: 600,
        mx: "auto",
        textAlign: "center",
        bgcolor: "background.paper",
      }}
    >
      {state === "processing" && (
        <>
          <CircularProgress
            variant="determinate"
            value={Math.min(progress, 100)}
            size={80}
            thickness={4}
            sx={{ color: "primary.main" }}
          />
          <Typography variant="h5" fontWeight="medium">
            {t`Finishing Setup...`}
          </Typography>
          <Typography variant="body1" color="text.secondary">
            {t`Please wait while we finish setting up your devices`}
          </Typography>
        </>
      )}

      {state === "success" && (
        <>
          {isRedirecting ? (
            <CircularProgress
              size={80}
              thickness={4}
              sx={{ color: "primary.main", mb: 2 }}
            />
          ) : (
            <CheckCircleOutlineIcon
              color="success"
              sx={{ fontSize: 80, mb: 2 }}
            />
          )}
          <Typography variant="h5" color="text.secondary">
            {isRedirecting
              ? isMobile
                ? t`Redirecting back to app...`
                : t`Redirecting back to dashboard...`
              : t`You're all set!`}
          </Typography>
        </>
      )}
    </Box>
  );
}
