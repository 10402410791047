import { createSelector } from "@reduxjs/toolkit";

export const selectStripeCart = (state) => state.stripe.cart.cart;
export const selectStripeRoamingAssetIds = (state) =>
  state.stripe.cart.roamingAssetIds;
export const selectStripeCartAsArray = createSelector(
  [selectStripeCart],
  (cart) => Object.entries(cart)
);

export const selectIsStripeCartDirty = (state) => state.stripe.cart.isDirty;
export const selectCancelledDevices = (state) =>
  state.stripe.cart.cancelledDevices;

export const selectCoupon = (state) => state.stripe.cart.coupon;
