import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { Box, Button, CircularProgress, Typography } from "@mui/material";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import { t } from "@lingui/macro";
import { isMobile } from "react-device-detect";
import { FETCH_MY_USER } from "store/account/slice";
import { sendToMobileInterface } from "utilities/mobile/interface";
import MobileQRCode from "assets/images/Branch.io-datatrail.app.link-PzdhOSvgakb.png";
import GoogleStore from "assets/images/google-play.png";
import AppleStore from "assets/images/AppleStore.png";
import {
  TITLE,
  DOCS,
  THEME,
  APP_STORE_LINK,
  GOOGLE_PLAY_LINK,
} from "configurations/appConfig";

export default function RegisterSuccess() {
  const dispatch = useDispatch();
  const history = useHistory();
  const [progress, setProgress] = useState(0);
  const [state, setState] = useState("processing");
  const [isRedirecting, setIsRedirecting] = useState(false);

  useEffect(() => {
    const TOTAL_DURATION = 4000;
    const STEPS = 200;
    const INTERVAL = TOTAL_DURATION / STEPS;

    // Progress animation
    const timer = setInterval(() => {
      setProgress((prevProgress) => {
        if (prevProgress >= 99.5) {
          clearInterval(timer);
          dispatch(
            FETCH_MY_USER({
              onSuccess: () => {
                setState("success");
                if (isMobile) {
                  // Show success message for 2 seconds, then show redirecting
                  setTimeout(() => {
                    setIsRedirecting(true);
                    setTimeout(() => {
                      sendToMobileInterface("success");
                    }, 500);
                  }, 2000);
                }
              },
            })
          );
          return 100;
        }
        return prevProgress + 100 / STEPS;
      });
    }, INTERVAL);

    return () => {
      clearInterval(timer);
    };
  }, [dispatch, history]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        minHeight: "100vh",
        gap: 3,
        p: 3,
        maxWidth: isMobile ? 600 : 800,
        mx: "auto",
        textAlign: "center",
        bgcolor: "background.paper",
      }}
    >
      {state === "processing" && (
        <>
          <CircularProgress
            variant="determinate"
            value={Math.min(progress, 100)}
            size={80}
            thickness={4}
            sx={{ color: "primary.main" }}
          />
          <Typography variant="h5" fontWeight="medium">
            {t`Setting Up Your Account...`}
          </Typography>
          <Typography variant="body1" color="text.secondary">
            {t`Please wait while we finish configuring your account`}
          </Typography>
        </>
      )}

      {state === "success" && (
        <>
          {isMobile ? (
            <>
              {isRedirecting ? (
                <CircularProgress
                  size={80}
                  thickness={4}
                  sx={{ color: "primary.main", mb: 2 }}
                />
              ) : (
                <CheckCircleOutlineIcon
                  color="success"
                  sx={{ fontSize: 80, mb: 2 }}
                />
              )}
              <Typography variant="h5" color="text.secondary">
                {isRedirecting ? t`Redirecting to app...` : t`You're all set!`}
              </Typography>
            </>
          ) : (
            <>
              <img
                src={THEME.logo}
                alt={TITLE}
                style={{
                  height: 80,
                  width: "auto",
                  marginBottom: 16,
                  objectFit: "contain",
                }}
              />
              <Typography variant="h4" gutterBottom fontWeight="medium">
                {t`Welcome to `}{" "}
                <span style={{ fontWeight: "bold" }}>{TITLE}!</span>
              </Typography>
              <Typography
                variant="body1"
                color="text.secondary"
                sx={{ maxWidth: 450, mb: 4 }}
              >
                {t`Your account has been successfully created. Get started by exploring your dashboard or download our mobile app to manage your devices on the go.`}
              </Typography>

              <Box
                sx={{
                  display: "flex",
                  gap: 4,
                  alignItems: "center",
                  justifyContent: "center",
                  width: "100%",
                  mb: 4,
                }}
              >
                <Button
                  variant="contained"
                  color="primary"
                  size="large"
                  onClick={() => history.push("/Assets")}
                  sx={{
                    minWidth: 200,
                    height: 48,
                  }}
                >
                  {t`Go to Dashboard`}
                </Button>

                <Box
                  sx={{
                    height: "48px",
                    width: "1px",
                    bgcolor: "divider",
                  }}
                />

                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: 2,
                  }}
                >
                  <img
                    src={MobileQRCode}
                    style={{ width: 48, height: 48 }}
                    alt="QR Code"
                  />
                  <Box>
                    <Typography
                      variant="subtitle2"
                      color="text.secondary"
                      gutterBottom
                    >
                      {t`Get the Mobile App`}
                    </Typography>
                    <Box
                      sx={{
                        display: "flex",
                        gap: 1,
                        "& img": {
                          height: 24,
                          cursor: "pointer",
                          transition: "opacity 0.2s",
                          "&:hover": {
                            opacity: 0.8,
                          },
                        },
                      }}
                    >
                      <img
                        src={GoogleStore}
                        alt="Google Play Store"
                        onClick={() => window.open(GOOGLE_PLAY_LINK)}
                      />
                      <img
                        src={AppleStore}
                        alt="Apple App Store"
                        onClick={() => window.open(APP_STORE_LINK)}
                      />
                    </Box>
                  </Box>
                </Box>
              </Box>

              <Typography
                variant="caption"
                sx={{
                  color: "text.secondary",
                  display: "flex",
                  alignItems: "center",
                  gap: 0.5,
                }}
              >
                {t`Need help?`}
                <a
                  href="https://info.mitrail.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{
                    color: "inherit",
                    textDecoration: "underline",
                  }}
                >
                  {t`Visit our Product FAQ`}
                </a>
              </Typography>
            </>
          )}
        </>
      )}
    </Box>
  );
}
